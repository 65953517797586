import { createApp } from 'vue';
import VueLazyload from 'vue-lazyload';
import i18next from 'i18next';
import I18NextVue from 'i18next-vue';

import PostCommentsCollection from './layouts/PostCommentsCollection.vue';

import CommentForm from './components/CommentForm.vue';
import PostComment from './components/PostComment.vue';

import ReportModel from './components/ReportModel.vue';

class PostCommentsComponent {
  constructor({ container }) {
    this.app = null;
    this.container = container;
  }

  // Initialize the component
  init() {
    this.app = createApp(PostCommentsCollection);
    this.app.use(I18NextVue, { i18next });
    this.app.use(VueLazyload, {
      preLoad: 2.6 // default 1.3
    });
    
    // comment
    this.app.component('comment-form', CommentForm);
    this.app.component('post-comment', PostComment);

    this.app.component('report-model', ReportModel);

    // Mount the Vue app to the specified container
    this.app.mount(this.container);
  }
}

export default PostCommentsComponent;
