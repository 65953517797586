<template>
  <div id='related-posts'>
    <template v-for="post in state.relatedPosts" :key="post.id">
      <div class="post-item" :title="post.title">
        <a :href="post.path" class="block w-full">
          <div class="aspect-square">
            <img v-lazy="post.cover_image_src" :alt="post.title" />
          </div>
        </a>
      </div>
    </template>
  </div>
</template>
<script>
import { state } from "../stores/postState";
import { defineComponent } from "vue";

export default defineComponent({
  data() {
    return {
      state
    };
  },
  mounted() {
    this.state.post_id = window.location.href.match(/\/posts\/(\d+)/)[1]
    this.state.relatedPosts = []

    this.getRelatedPosts()
  },
  methods: {
    async getRelatedPosts(){
      try {
        const api_path = '/api/v1/posts/' + this.state.post_id + '/related_posts'
        const response = await fetch(api_path);

        if (response.ok) {
          const data = await response.json();
          const postsData = data['data']

          if (postsData.length > 0) {
            this.state.relatedPosts = postsData;
          }
        }
      } catch (error) {
        this.state.relatedPosts = []
      }
    }
  }
});
</script>
