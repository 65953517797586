<template>
  <form id='post-comment-form' method="put">
    <template v-if='(commentState.reviewable && !commentState.has_review) || commentState.edit_target?.stars > 0'>
      <div class="review-rating mb-2 flex flex-col sm:flex-row sm:items-center">
        <div class='text-14px mr-4'>
          {{ $t('comment_form.rating') }}
        </div>
        <div class="flex items-center">
          <template v-for="rating in [1, 2, 3, 4, 5]" :key="rating">
            <label
              class='flex items-center justify-center'
              @click.prevent="ratingStarChange(rating)"
              @mouseenter="ratingEnter(rating)"
              @mouseleave="ratingLeave"
            >
              <template v-if="ratingStarHover >= rating">
                <span class="icon star-hover"></span>
              </template>
              <template v-else-if="ratingStar >= rating">
                <span class="icon star"></span>
              </template>
              <template v-else>
                <span class="icon star-empty"></span>
              </template>
            </label>
          </template>
        </div>
      </div>
    </template>

    <div class="flex flex-col w-full relative">
      <textarea
        class='default-input-klass'
        name="comment_content"
        v-model='commentContent'
        :placeholder="$t('comment_form.comment_placeholder')"
        maxlength="1000"
      />
      <div class="content-count">
        {{ commentContentCount }}
      </div>
    </div>

    <div class='flex -mx-1 my-1'>
      <div v-for='(file, index) in commentImageFiles' class='relative'>
        <div
          @click.prevent="commentImgDelete(index)"
          class='delete-icon absolute w-5 h-5 right-0 top-0 cursor-pointer'
        ></div>
        <div
          v-lazy:background-image="parseImgUrl(file)"
          class='image rounded-8px w-10 h-10 bg-cover m-1'
        ></div>
      </div>
    </div>

    <div class="flex justify-between">
      <label class="btn btn-sm btn-secondary rounded-full pl-1 pr-2">
        <div class="flex items-center">
          <span class="icon w-8 h-8 -my-2"></span>
          <span>{{ $t('comment_form.add_img_btn') }}</span>
        </div>
        <input id="comment_images"
               class="hidden"
               multiple="multiple"
               name="comment[images_attributes][][file]"
               accept="image/jpeg,image/png,image/jpg"
               type="file"
               v-on:change="commentImgUpload">
      </label>
      <span>
        <button
          v-if='commentContent'
          class="px-2 btn-sm btn mr-2 hover:text-orange-20"
          @click.prevent="cancelNewComment"
        >
          {{ $t('comment_form.cancel') }}
        </button>
        <button
          v-if='uploading'
          id='submit-comment'
          class="px-2 btn-sm rounded-full btn btn-secondary"
          :disabled="true"
        >
          {{ $t('comment_form.uploading') }}
        </button>
        <button
          v-else
          id='submit-comment'
          class="px-2 btn-sm rounded-full btn btn-secondary"
          @click.prevent="submitComment"
          :disabled="!commentContent"
        >
          {{ $t('comment_form.submit') }}
        </button>
      </span>
    </div>
  </form>
</template>

<script>
import { defineComponent } from "vue";
import { state, commentState } from "../stores/postState";

export default defineComponent({
  props: ['fetchCommentItemData'],
  data() {
    return {
      uploading: false,
      ratingStar: 0,
      ratingStarHover: 0,
      commentContent: '',
      commentImageFiles: [],

      state,
      commentState,
    };
  },
  computed: {
    commentContentCount() {
      if (this.commentContent.length > 0 && this.state.curr_profile == null){
        window.location.href = '/users/sign_in'
      }

      return `${this.commentContent.length} / 1000`;
    }
  },
  mounted() {
    const curr_comment = this.commentState.edit_target;

    this.ratingStar     = (curr_comment && curr_comment.stars) || 0
    this.commentContent = (curr_comment && curr_comment.content) || ''

    if (curr_comment && curr_comment.images.length > 0) {
      this.fetchCommentImages(curr_comment.images);
    }
  },
  methods: {
    fetchCommentImages(images) {
      images.forEach((img) => {
        fetch(img.url)
          .then((response) => response.blob())
          .then((blob) => {
            var filename = img.url.split("/").slice(-1)[0];
            var file = new File([blob], filename, { type: blob.type });

            this.commentImageFiles.push(file);
          });
      });
    },
    commentImgUpload(e) {
      console.log('uplaod file', e.target.files)
      e.target.files.forEach((file) => {
        this.commentImageFiles.push(file)
      })
    },
    commentType() {
      if ( this.ratingStar > 0 ) {
        return 'TempPostReview'
      } else {
        return 'PostComment'
      }
    },
    commentImgDelete(index) {
      this.commentImageFiles.splice(index, 1);
    },
    parseImgUrl(file){
      return URL.createObjectURL(file)
    },
    cancelNewComment() {
      this.ratingStar = 0;
      this.commentContent = "";
      this.commentImageFiles = [];
      this.commentState.edit_target = null;
    },
    ratingEnter(value) {
      this.ratingStarHover = value;
    },
    ratingLeave() {
      this.ratingStarHover = 0;
    },
    ratingStarChange(value) {
      this.ratingStar = value;
    },
    submitComment(e) {
      this.uploading = true

      let requestUrl;
      let requestMethod;

      if (this.commentState.edit_target) {
        requestUrl = `/posts/${this.state.post_id}/comments/${this.commentState.edit_target.id}`;
        requestMethod = "put";
      } else {
        requestUrl = `/posts/${this.state.post_id}/comments`;
        requestMethod = "post";
      }

      const formData = new FormData()
      formData.append("comment[stars]", this.ratingStar);
      formData.append('comment[type]', this.commentType())
      formData.append('comment[content]', this.commentContent)

      this.commentImageFiles.forEach(function(file) {
        formData.append('comment[images_attributes][][file]', file)
      })

      fetch(requestUrl, {
        method: requestMethod,
        headers: {
          "X-CSRF-Token": this.state.CSRFtoken
        },
        body: formData,
      })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          return res.json().then(errData =>{
            throw new Error(errData.message || 'Error in submission');
          })
        }
      })
      .then((data) => {
        this.uploading = false

        this.ratingStar = 0;
        this.commentContent = '';
        this.commentImageFiles = [];
        this.commentState.edit_target = null;

        this.fetchCommentItemData();
      })
      .catch((error) => {
        this.uploading = false;
        this.fetchCommentItemData();
      });
    }
  }
});
</script>
../stores/postState