// state.js
import { reactive } from 'vue';

export const state = reactive({
  post_id: null,
  CSRFtoken: null,
  curr_profile: null,
  post_profile: null,
  images: [],
  aspectRatio: [1, 1]
})

export const commentState = reactive({
  reviewable: false,
  has_review: false,
  edit_target: null,
  scroll_target_id: null,
  order_type: '',
  comment_items: [],
  selected_comment_items: [],
  reviewStars: [],
});

// FIXME 之後移除
export const reviewState = reactive({
  reviewable: false,
  has_review: false,
  edit_target: null,
  reviewStars: [],
  review_items: [],
  selected_review_items: [],
  review_items_not_found: false,

  curr_profile_review: null,
});

export const reportState = reactive({
  openModal: false,
  targetId: null,
  targetType: null,
  reportKey: null,
  reportReason: null
});

export const slideState = reactive({
  fetchPath: '/comment_images',
  openSlide: false,
  images: [],
  startImageId: null,
  aspectRatio: [1, 1]
});

export const exchangeState = reactive({
  rates: null
});
