<template>
  <div class="w-full flex flex-wrap mx-1.5"> 
    <div id="post-item-collection" class="flex-1">
      <div v-if="items && items.length > 0" v-for="post in items" :key="post.id">
        <post-item :post="post" :is_authenticated="is_authenticated"></post-item>
      </div>
      <div v-else-if="loading" class="text-center post-item-loading">
        {{ $t('post_index_collection.loading') }}
      </div>
    </div>
  </div>
</template>

<script>

import { defineComponent } from "vue";
import i18next from "i18next";

export default defineComponent({
  props: {
    scope: {
      type: String,
      required: true
    },
  },
  data() {
    return {
      currPage: 0,
      autoload: true,
      is_authenticated: false,
      // collection list
      loading: false,
      username: '',
      itemsCols: 4,
      itemsRows: 2,
      itemsCount: 8,
      items_not_found: false,
      items_data: [],
      items: [],
      current_collection:[]
    };
  },
  mounted() {
    this.setupItemColsCount()

    this.username = this.getUsernameFromURL();
    this.fetchItemData(this.currPage, this.itemsCount*2)
    this.currPage += 1

    if (window.isFirefox) {
        window.addEventListener('scroll', this.handleScrollEvent, true);
      } else {
        window.addEventListener('scroll', this.handleScrollEvent);
      }
  },
  methods: {
    getUsernameFromURL() {
      const url = window.location.href;
      const match = this.scope === 'saved_posts' ? url.match(/\/p\/([^\/]+)\/saved_posts/) : url.match(/\/p\/([^\/]+)\/posts/);
      return match ? match[1] : '';
    },
    
    async fetchItemData(currPage, perPage) {
      const url = "/api/v1/profiles/" + this.username + "/get_" + this.scope
      this.loading = true;
      this.items_not_found = false;
      // 資料要往後拉，所以 +1
      let curr_params = {
        page: (currPage || this.currPage) + 1,
        per_page: perPage || this.itemsCount
      };
      
      try {
        const response = await fetch(
          url + "?" + new URLSearchParams(curr_params)
        );

        if (response.ok) {
          this.loading = false;

          const data = await response.json();
          const itemsData = data["data"];
          this.is_authenticated = data['is_authenticated']
          // 如果 params page > resp total_page
          // 等於拉到底了，就不用 autoload 了      
          if (curr_params["page"] > data["total_pages"]) {
            this.autoload = false;
          } else if (itemsData.length > 0) {
            // 如果有資料就要塞到 items 裏面，並更新頁碼
            this.items.push(...itemsData);
            this.currPage++;
          }
        }
      } catch (error) {
        console.log(error);
        this.items = [];
        this.autoload = false;
      }
    },

    setupItemColsCount() {
      if (window.innerWidth >= 1024) {
        this.itemsCols = 4;
      } else if (window.innerWidth >= 780) {
        this.itemsCols = 3;
      } else if (window.innerWidth >= 705) {
        this.itemsCols = 2;
      } else {
        this.itemsCols = 1;
      }

      if (this.itemsCols != 1) {
        this.itemsCount = this.itemsCols * this.itemsRows;
      } else {
        this.itemsCount = 10;
      }
    },
    handleScrollEvent() {
      if (this.autoload == false) {
        return;
      }
      const scrollableHeight = document.body.scrollHeight - window.innerHeight - 150;
      const scrolledDistance = Math.max(
        window.pageYOffset,
        document.documentElement.scrollTop,
        document.body.scrollTop
      );

      if (scrolledDistance >= scrollableHeight) {
        if (this.loading == false) {
          this.fetchItemData();
        }
      }
    }
  }
})
</script>
